import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Single Page App`}</h1>
    <p>{`Les applications web proposées par `}<a parentName="p" {...{
        "href": "http://perceptible.fr"
      }}>{`perceptible.fr`}</a>{` dans certaines de ses réalisations sont des `}<strong parentName="p">{`Single Page Apps`}</strong>{`. `}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`A moins qu'il ne soit nécessaire d'avoir des traitement « en arrière plan » ou de partager en temps réel des données entre utilisateurs le serveur n'est pas toujours indispensable.`}</strong>{` `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Les données restent locales et confidentielles même s'il n'y a pas de login/ mot de passe.`}</strong>{` `}</p>
    </blockquote>
    <h2>{`Architecture`}</h2>
    <p>{`Une Single Page Application (SPA, application web monopage en français) est une application accessible via une page web qui « contient tout ». Son intérêt est d'éviter le chargement d'une nouvelle page à chaque action demandée, et de fluidifier ainsi l'expérience utilisateur. L’ensemble des éléments de l'application est chargé (html, images, css, javascript et données) en une fois (ou plus exactement à la demande en fonction des actions de l'utilisateur).`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`C’est un peu comme si le navigateur web devenait un ordinateur qui exécutait localement un programme téléchargé sur le web.`}</strong></p>
    </blockquote>
    <p>{`Cette philosophie diffère radicalement de l’application web client/serveur « classique » composée d’aller-retours continuels entre le « client ignorant des données globales et de ce qu'elles signifient » et le serveur distant tout puissant. `}</p>
    <h2>{`Fonctionnement`}</h2>
    <p>{`La SPA est accessible via une adresse URL dans un navigateur: un clic sur un marque page ou sur un raccourci sur le bureau ou l’écran d’accueil.`}</p>
    <h3>{`Importation des données`}</h3>
    <p>{`La SPA ne contient aucune donnée en propre et ne peut y accéder elle même. Les données sont contenues dans un fichier Excel qui est:`}</p>
    <ul>
      <li parentName="ul">{`placé à un endroit fixe. L’utilisateur délègue ses droits en lecture.`}</li>
      <li parentName="ul">{`désigné en cours d’utilisation de deux manières:`}
        <ul parentName="li">
          <li parentName="ul">{`un bouton « lire le fichier » ouvre l’explorateur de fichiers et permet de désigner le classeur Excel (local ou distant)  `}</li>
          <li parentName="ul">{`un glisser/déposer du fichier dans la fenêtre (sur ordinateur  uniquement)`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Comportement « responsive »`}</h3>
    <p>{`La page adapte dynamiquement son contenu à la taille du dispositif: la fenêtre sur le desktop, l’orientation sur la tablette ou le téléphone. Il ne s’agit pas seulement d’une mise à l’échelle mais aussi de ré-organisation « intelligente » de l’affichage.`}</p>
    <h3>{`Impression et exportation`}</h3>
    <p>{`La SPA est capable d’exporter son affichage sous plusieurs formats en plus du simple « imprimer » du navigateur.
Sont techniquement disponibles:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`pdf`}</strong>{`: impression papier sur plusieurs pages, archivage et diffusion`}</li>
      <li parentName="ul"><strong parentName="li">{`png`}</strong>{`: image de taille ajustable pour réutilisation dans des rapports par exemple Word ou PowerPoint`}</li>
      <li parentName="ul"><strong parentName="li">{`svg`}</strong>{`: dessin vectoriel capable d’être affiché à toute résolution sans perte de netteté`}</li>
      <li parentName="ul"><strong parentName="li">{`xlsx`}</strong>{`: sous-ensemble des données filtrées et pouvant aussi contenir les diagrammes `}</li>
    </ul>
    <h3>{`Mémorisation des données et préférences de la page`}</h3>
    <p>{`La SPA est capable de mémoriser dans le navigateur les préférences ou données déjà renseignées de telle sorte que le rafraichissement de la page ne perde pas les choix récents: couleur, position des blocs, filtres, … Cette mémorisation s’effectue localement au navigateur de la machine utilisée et est persistante d’une session à l’autre. Y compris après redémarrage de la machine.`}</p>
    <h3>{`Multi-onglets et fenêtres`}</h3>
    <p>{`L'application peut, si c'est utile, être conçue sur plusieurs fenêtres du navigateur en utilisant les mécanismes de communication locale inter fenêtres sans avoir besoin d'un quelconque serveur distant.  `}</p>
    <h3>{`Hébergement de la SPA`}</h3>
    <p>{`La SPA est constitué de quelques fichiers qu’il convient de rendre accessible à l’utilisateur soit sur un réseau privé ou public à condition de sécuriser les données.`}</p>
    <h2>{`Bénéfices`}</h2>
    <p>{`Cette architecture de SPA très simple et minimale comporte de multiples avantages: `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`critère`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`avantage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`- Sécurité`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Préserve la confidentialité des données`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`- Robustesse`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Capable de résister aux pannes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`- Sobriété`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Économe en moyens et ressources`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`- Performance`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Très confortable à utiliser`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Sécurité`}</h3>
    <ol>
      <li parentName="ol"><strong parentName="li">{`La SPA ne lit que les données que l’utilisateur désigne`}</strong>{`: Elle ne peut avoir accès qu’aux données déjà désignées et accessibles à l’utilisateur. `}</li>
      <li parentName="ol"><strong parentName="li">{`La SPA ne peut pas modifier les données`}</strong>{`: C’est la conséquence d’une sécurité globale des navigateurs, une page web ne peut pas `}<strong parentName="li">{`écrire`}</strong>{` dans un fichier local. Elle ne peut que proposer le téléchargement d’un nouveau fichier.`}</li>
      <li parentName="ol"><strong parentName="li">{`La SPA n’ouvre pas de brèche de confidentialité`}</strong>{`: A l’ouverture de la SPA le navigateur charge l’intégralité de l'application strictement authentifiée dans le navigateur. C’est elle qui va lire, décoder et afficher les données sur l’écran. `}<strong parentName="li">{`Les données ne sortent pas de la machine qui exécute la SPA`}</strong>{`. `}</li>
    </ol>
    <h3>{`Robustesse`}</h3>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Aucun risque de perte de données`}</strong>{`: la SPA ne peut pas altérer les données source. Seule l’interruption brutale du navigateur ou de la machine utilisée peut provoquer au pire la perte de la sélection courante.`}</li>
      <li parentName="ol"><strong parentName="li">{`Disponibilité totale`}</strong>{`: la SPA est disponible autant que l’infrastructure d’accès aux données l’est. `}</li>
      <li parentName="ol"><strong parentName="li">{`Résistant à la coupure internet`}</strong>{`: La SPA a la possibilité de s’exécuter en local (ordinateur ou réseau entreprise) sans nécessiter d’accès internet. `}</li>
    </ol>
    <h3>{`Sobriété`}</h3>
    <p>{`La SPA est particulièrement économe en moyens et ressources d’exploitation:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Pas besoin de serveur`}</strong>{`: l’intégralité des calculs s’effectuent sur les machines des utilisateurs. Les données restent à l’endroit où elle se trouvent. La SPA produit des éléments de rapports et d’analyse qui sont gérés par l’utilisateur comme des fichiers. `}</li>
      <li parentName="ol"><strong parentName="li">{`Ne nécessite aucune administration`}</strong>{`: inutile de gérer des comptes ou des autorisations d’accès, pas besoin de sauvegarde particulière, inutile de corriger l’intégrité des données manuellement après un crash, …`}</li>
      <li parentName="ol"><strong parentName="li">{`Une seule application pour tous les supports`}</strong>{`: la même SPA fonctionne sur les différents supports: ordinateur, tablette, téléphone, en adaptant dynamiquement son affichage aux tailles disponibles.`}</li>
    </ol>
    <h3>{`Performance`}</h3>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Fluidité inégalée`}</strong>{`: une fois les données nécessaires chargées dans le navigateur, il est possible d'utiliser des techniques de filtrage `}<strong parentName="li">{`à la souris`}</strong>{` qui réagissent instantanement.  `}</li>
      <li parentName="ol"><strong parentName="li">{`Chargement à la volée`}</strong>{`: le chargement des données nécessaires s'effectue au fur et à mesure des fonctionnalités et des filtrages activés par l'utilisateur. `}</li>
      <li parentName="ol"><strong parentName="li">{`Optimisation statique`}</strong>{`:  peu importe la complexité fonctionnelle de l'application, elle sera vue comme une collection de simples pages html.`}</li>
    </ol>
    <h2>{`Un exemple de SPA`}</h2>
    <p>{`Cliquez là -> `}<a parentName="p" {...{
        "href": "https://alainro.github.io/cashflow/"
      }}>{`Simulateur de trésorerie`}</a></p>
    <h2>{`La Progressive Web App`}</h2>
    <p>{`La Progressive Web App (PWA) permet d'aller encore plus loin sur les supports mobiles (tablettes et téléphone). L'application devient quasiment indiscernable d'une application native (plus de barre de navigation, icône de démarrage sur l'écran d'accueil, splash screen, fonctionnelle hors connexion, ...). Pour en savoir plus:
`}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Progressive_web_app"
      }}>{`wikipedia`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      